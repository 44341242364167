@import "../../main.scss";
@import "../../theme.scss";

.radioButtons {
	background: white !important;
	border-color: $primaryColor !important;
	border-radius: 4px !important;
}

.radioButtons::before {
	background-color: $primaryColor !important;
	height: 85% !important;
	top: 1px !important;
	left: 0px !important;
	width: 0.8px !important;
}

.radioButtons:hover {
	background: white !important;
	border-color: $thirdGreen !important;
	color: $thirdGreen !important;
	border-radius: 4px !important;
}

.questionBorder {
	border: 2px solid #376e6f;
	border-radius: 20px;
	padding: 15px;
}

.categoryLabel {
	font-size: x-large !important;
}

.saveBtn {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.delete {
	outline: none;
	background-color: transparent;
	border: none;
	float: right;
	&:hover {
		cursor: pointer;
	}
}

.container {
	.firstLine {
		@include layout(column, flex-start, flex-start);
		width: 100%;
		:global {
			.ant-row {
				width: 100% !important;
			}
		}
		.checkbox {
			@include layout(row, flex-start, flex-start);
		}
		textarea,
		.numberInput {
			border: 0.5px solid #d9d9d9;
			outline: none;
			resize: none;
			box-shadow: none;
			border-radius: 4px !important;
			color: rgb(0, 0, 0) !important;
			margin-bottom: 1%;
			width: 100%;
			height: 2.2rem;
			padding: 5px 5px 5px 1%;
			&:hover,
			&:focus,
			&:active {
				border: 0.5px solid #d9d9d9;
				outline: none;
				resize: none;
				box-shadow: none;
				border-radius: 4px !important;
			}
		}

		.customSelect {
			text-align: left;
		}

		textarea::placeholder,
		.numberInput::placeholder {
			opacity: 0.25;
		}
	}
	.secondLine {
		margin-top: 5%;
		width: 100%;
		@include layout(row, space-between, flex-start);

		.firstColumn {
			@include layout(row, space-between, center);
			width: 250px;
		}
		.secondColumn {
			@include layout(row, flex-end, center);
		}
	}
}
